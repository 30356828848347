const themeConfig = {
  token: {
    colorPrimary: 'rgb(223 60 38)', // Can't use var here as it will not generate shades.
    radiusBase: '4px',
    controlRadius: '4px',
    fontFamily: 'unset',
    controlItemBgActive: 'var(--bg-light-black)',
    controlItemBgHover: 'var(--bg-light-black)',
    controlItemBgActiveHover: 'var(--bg-light-black)',
    colorLink: 'var(--color-primary)',
  },
  components: {
    Typography: {
      colorLink: 'var(--color-primary)',
      colorLinkHover: 'var(--color-black)',
    },
    Button: {
      primaryShadow: 'unset',
      colorLink: 'var(--color-primary)',
    },
    Input: {
      activeShadow: 'unset',
    },
    DatePicker: { activeShadow: 'unset' },
  },
};

export default themeConfig;
