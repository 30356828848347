import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_IMPORT_EXPORT_LIST = gql`
  query listImportedFile(
    $filter: ListImportedFileFilter!
    $sort: [ListImportedFileSort!]!
  ) {
    listImportedFile(filter: $filter, sort: $sort) {
      data {
        id
        sourceFileName
        failedFileName
        status
        createdCount
        updatedCount
        failedCount
        totalCount
        sourceFileUrl
        failedFileUrl
      }
      count
    }
  }
`;
