import { gql } from '@apollo/client';

export const DELETE_ASSET = gql`
  mutation deleteAsset($where: DeleteAssetWhereInput!) {
    deleteAsset(where: $where) {
      message
    }
  }
`;

export const INVITE_USERS = gql`
  mutation inviteUser($data: InviteUserInput!) {
    inviteUser(data: $data) {
      message
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($where: DeleteUserInput!) {
    deleteUser(where: $where) {
      message
    }
  }
`;
