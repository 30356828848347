import {
  DeleteOutlined,
  ImportOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Badge, Button, Popconfirm, Space, Table, Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { camelCase, startCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  DEFAULT_SORT_ORDER,
  FILTERS,
  LIMIT,
  SORT_BY,
  SORT_ON,
  USER_STATUS,
  defaultDateFormat,
} from '../../common/constants';
import SearchComponent from '../../components/common/SearchComponent';
import ErrorModal from '../../components/common/modals/ErrorModal';
import InviteModal from '../../components/common/modals/InviteModal';
import UploadEmailModal from '../../components/common/modals/UploadEmailModal';
import { LIST_USER, ResendInvitation } from '../contents/graphql/queries';
import '../index.less';
import { DELETE_USER } from './graphql/mutations';

dayjs.extend(utc);

export default function UserList() {
  const [uploadModal, setUploadModal] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [isAlertOpen, setIsAlertModal] = useState(false);
  const [isInviteModal, setIsInviteModal] = useState(false);
  const initialFilters = {
    sort: [
      {
        sortBy: SORT_BY.DESC,
        sortOn: SORT_ON.createdAt,
      },
    ],
    filter: {
      limit: LIMIT,
      skip: 0,
      search: '',
      current: 1,
    },
  };

  const [tableParams, setTableParams] = useState(initialFilters);

  const [listUsers, { loading, data }] = useLazyQuery(LIST_USER, {
    onCompleted: (res) => {
      setDataList(res?.listUser?.data || []);
    },
    onError: () => {},
    fetchPolicy: 'network-only',
  });

  const [resendInvitation, { loading: resendInvitationLoading }] = useMutation(
    ResendInvitation,
    {
      onCompleted: () => {
        setTableParams({
          ...tableParams,
          filter: {
            ...tableParams?.filter,
            skip: tableParams?.filter?.skip,
            current: tableParams?.filter?.current,
          },
        });
      },
      onError: () => {},
    },
  );

  const [deleteUser, { loading: deleteLoading }] = useMutation(DELETE_USER, {
    onCompleted: () => {
      listUsers({
        variables: {
          ...tableParams,
          filter: {
            limit: LIMIT,
            skip: tableParams?.filter?.skip,
            search: tableParams?.filter?.search,
          },
        },
        onCompleted: (res) => {
          setDataList(res?.listUser?.data || []);
        },
      });
    },
    onError: () => {},
  });

  useEffect(() => {
    const payload = { ...tableParams };
    delete payload?.filter?.current;
    listUsers({
      variables: payload,
    });
  }, [tableParams]);

  const handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const payload = { ...filters };
    delete payload?.createdAt;
    setTableParams({
      ...tableParams,
      sort: {
        sortOn: sorter?.field === 'fullName' ? 'name' : sorter?.field,
        sortBy:
          sorter?.order === 'DESC' || sorter?.order === 'descend'
            ? SORT_BY.DESC
            : SORT_BY.ASC,
      },
      filter: {
        ...tableParams?.filter,
        limit: pageSize,
        current,
        skip: current * pageSize - pageSize,
        status: payload?.status,
      },
    });
  };

  const renderTags = ({ status }) => {
    switch (status) {
      case USER_STATUS.INVITED:
        return (
          <Tag color="processing">
            {startCase(camelCase(USER_STATUS.INVITED))}
          </Tag>
        );
      case USER_STATUS.LOGGED_IN:
        return (
          <Tag color="success">
            {startCase(camelCase(USER_STATUS.LOGGED_IN))}
          </Tag>
        );
      case USER_STATUS.EXPIRED:
        return (
          <Tag color="error">{startCase(camelCase(USER_STATUS.EXPIRED))}</Tag>
        );
      default:
        return '-';
    }
  };

  const resendInviteLink = ({ email }) => {
    resendInvitation({
      variables: {
        where: {
          email,
        },
      },
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      render: (name) => name ?? '-',
      sorter: true,
      sortDirections: DEFAULT_SORT_ORDER,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (email) => email ?? '-',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: FILTERS.USER_LOGGED_IN_STATUS,
      render: (val, item) => renderTags(item),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (val) => dayjs(val).utc().format(defaultDateFormat) ?? '-',
      defaultSortOrder: SORT_BY.DESC,
      sorter: true,
      sortDirections: DEFAULT_SORT_ORDER,
    },
    {
      title: '',
      dataIndex: 'id',
      align: 'center',
      render: (id, record) => (
        <Space size="small" key={id}>
          <Popconfirm
            title="Delete"
            description="Are you sure to delete this user?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => deleteUser({ variables: { where: { id } } })}
            okButtonProps={{ loading: deleteLoading }}
          >
            <DeleteOutlined className="pointer delete-icon" />
          </Popconfirm>
          {record?.status === USER_STATUS.LOGGED_IN ? (
            <Button
              disabled={record?.status === USER_STATUS.LOGGED_IN}
              onClick={() => resendInviteLink(record)}
            >
              Resend
            </Button>
          ) : (
            <Tooltip
              title={
                record?.status === USER_STATUS.EXPIRED
                  ? 'This link is expired, please click to resend'
                  : `${record?.linkExpireMessage}`
              }
            >
              <Button onClick={() => resendInviteLink(record)}>Resend</Button>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  const handleModalClose = () => {
    setIsAlertModal(false);
  };

  const handleSearch = (val) => {
    const search = val?.trim();
    setTableParams({
      ...tableParams,
      filter: {
        ...tableParams?.filter,
        limit: LIMIT,
        skip: 0,
        search,
        current: 1,
      },
    });
  };

  return (
    <div className="content-wrapper">
      <Space direction="vertical" className="d-flex" size={16}>
        <div className="d-flex justify-between align-center flex-wrap">
          <h2>
            Users
            <Badge
              count={data?.listUser?.count}
              showZero
              color="var(--color-primary)"
            />
          </h2>
          <div className="filter-wrapper d-flex gap-8">
            <SearchComponent
              placeholder="Quick find by Name or Email"
              getData={handleSearch}
            />
            <Button
              onClick={() => setUploadModal(true)}
              icon={<ImportOutlined />}
            >
              Import
            </Button>
            <Button
              onClick={() => setIsInviteModal(true)}
              icon={<PlusOutlined />}
            >
              Invite
            </Button>
          </div>
        </div>
        <div className="responsive-table">
          <Table
            columns={columns}
            dataSource={dataList}
            pagination={{
              position: ['bottomCenter'],
              total: data?.listUser?.count,
              current: tableParams?.filter.current,
              pageSize: tableParams?.filter.limit,
              responsive: true,
            }}
            loading={loading || resendInvitationLoading}
            className="content-list-table"
            tableLayout="auto"
            onChange={handleTableChange}
            locale={{
              filterConfirm: 'Save',
              emptyText: 'No user available',
            }}
          />
        </div>
      </Space>
      <InviteModal
        open={isInviteModal}
        onCancel={() => setIsInviteModal(false)}
        fetchData={() => {
          setTableParams({
            ...tableParams,
            filter: {
              ...tableParams?.filter,
              skip: 0,
              current: 1,
            },
          });
        }}
      />
      <ErrorModal
        open={isAlertOpen}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        callback={() => {
          handleModalClose();
          setUploadModal(true);
        }}
      />
      <UploadEmailModal
        open={uploadModal}
        onCancel={() => setUploadModal(false)}
        fetchData={() => {
          setTableParams({
            ...tableParams,
            filter: {
              ...tableParams?.filter,
              skip: 0,
              current: 1,
            },
          });
        }}
      />
    </div>
  );
}
