import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Card, Col, Form, Input, Row, Space } from 'antd';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import { MESSAGE } from '../../common/constants';
import {
  formValidatorRules,
  handleProtectedNavigation,
} from '../../common/utils';
import RouterPrompt from '../../components/RouterPrompt';
import LoaderComponent from '../../components/common/LoaderComponent';
import useRouter from '../../hooks/useRouter';
import { UPDATE_CURRENT_USER } from '../auth/graphql/mutations';
import { GET_CURRENT_USER, GET_SIGNED_URL } from '../auth/graphql/queries';

const { required, name, email } = formValidatorRules;

function Profile() {
  const { dispatch, initializeAuth, getToken } = useContext(AppContext);
  // const [imageUrl, setImageUrl] = useState(); // commenting for future use
  const [form] = Form?.useForm();
  const { navigate } = useRouter();
  const idToken = getToken();
  const [loading, setLoading] = useState(false);

  const { loading: userLoading } = useQuery(GET_CURRENT_USER, {
    onCompleted: (res) => {
      if (res?.user) {
        form?.setFieldsValue(res?.user);
        // commenting for future use
        // setImageUrl(res?.user?.profileImage);
      }
    },
  });
  const [showPrompt, setShowPrompt] = useState(false);
  const [isPrompt, setIsPrompt] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const [updateCurrentUser] = useMutation(UPDATE_CURRENT_USER, {
    onCompleted: (res) => {
      dispatch({
        type: 'SET_CURRENT_USER',
        data: res?.updateUser?.data,
      });
      initializeAuth(idToken, res?.updateUser?.data);
      setCurrentImage(null);
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    },
  });

  const [getSignedUrl] = useLazyQuery(GET_SIGNED_URL, {
    onError: () => {},
  });

  // commenting for future use
  // const handleChange = async (info) => {
  //   if (beforeUpload(info?.file)) {
  //     setCurrentImage(info.file);
  //     setImageUrl(await getBase64(info.file));
  //   }
  // };

  // const uploadButton = (
  //   <Button type="button" ghost>
  //     <PlusOutlined />
  //     <div>Upload</div>
  //   </Button>
  // );

  const handleBack = () => {
    setIsPrompt(!handleProtectedNavigation(!showPrompt, navigate, -1));
  };

  const handleShowPrompt = () => {
    setShowPrompt(true);
  };

  const handleOk = () => {
    handleProtectedNavigation(true, navigate, -1);
  };

  const handleClose = () => {
    setIsPrompt(false);
  };

  const onFinish = async (values) => {
    setShowPrompt(false);
    setLoading(true);

    const userObj = {
      firstName: values?.firstName?.trim(),
      lastName: values?.lastName?.trim(),
      profileImage: '',
    };

    if (currentImage) {
      getSignedUrl({
        variables: {
          data: {
            fileName: currentImage?.name,
          },
        },
        onCompleted: async ({ getProfileImageUploadSignedUrl }) => {
          try {
            const response = await axios?.put(
              getProfileImageUploadSignedUrl?.signedUrl,
              currentImage,
            );
            if (response?.status === 200) {
              userObj.profileImage = getProfileImageUploadSignedUrl?.key;
              updateCurrentUser({
                variables: {
                  data: userObj,
                },
              });
            }
          } catch (err) {
            return err;
          } finally {
            setLoading(false);
          }
        },
        onError: () => {
          setLoading(false);
        },
      });
    } else {
      updateCurrentUser({
        variables: {
          data: userObj,
        },
      });
    }
  };

  if (userLoading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <Form
        form={form}
        className="sticky-action-form"
        onFieldsChange={handleShowPrompt}
        layout="vertical"
        onFinish={onFinish}
      >
        <Card
          className="ant-body-scroll"
          title="Profile"
          actions={[
            <div key="action-button" className="text-right">
              <Space>
                <Button onClick={handleBack} disabled={loading}>
                  Cancel
                </Button>
                <Button type="primary" loading={loading} htmlType="submit">
                  Save
                </Button>
              </Space>
            </div>,
          ]}
        >
          <div className="card-body-wrapper">
            <Row gutter={[16, 16]}>
              {/* commenting for future use */}
              {/* <Col xs={24} lg={24} xl={24}>
                <Form.Item name="profileImage" label="Profile Photo">
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    beforeUpload={() => false}
                    showUploadList={false}
                    accept={COMMON_ALLOWED_FILE_TYPES}
                    onChange={handleChange}
                  >
                    {imageUrl ? (
                      <img src={imageUrl} alt="avatar" className="avatar-img" />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </Form.Item>
              </Col> */}
              <Col xs={24} lg={8} xl={8}>
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[{ ...required, message: MESSAGE.required }, name]}
                >
                  <Input placeholder="First name" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={8} xl={8}>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[{ ...required, message: MESSAGE.required }, name]}
                >
                  <Input placeholder="Last name" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={8} xl={8}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[{ ...required, message: MESSAGE.required }, email]}
                >
                  <Input disabled placeholder="Email" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Card>
      </Form>
      <RouterPrompt
        isPrompt={isPrompt}
        handleOK={handleOk}
        handleCancel={handleClose}
      />
    </>
  );
}
export default Profile;
