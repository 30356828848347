import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Spin } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { Logo } from '../../assets/svg';
import { COMPANY_INFO, MESSAGE, ROUTES } from '../../common/constants';
import { formItemProps, formValidatorRules } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import './auth.less';
import { LOGIN } from './graphql/mutations';

const { required, email } = formValidatorRules;

const Login = () => {
  const [form] = Form.useForm();
  const { navigate } = useRouter();
  const { initializeAuth } = useContext(AppContext);
  const [loginMutate, { loading: loginLoading }] = useMutation(LOGIN, {
    onError() {},
  });

  function successCallback(accessToken, userData, refreshToken) {
    initializeAuth(accessToken, userData, refreshToken);
    navigate(ROUTES?.MAIN);
  }

  const onFinish = async (values) => {
    const formValues = {
      email: values?.email,
      password: values?.password,
    };
    loginMutate({
      variables: { data: { ...formValues } },
      onCompleted: (response) => {
        if (response?.loginUser) {
          const {
            accessToken,
            refreshToken,
            data: userData,
          } = response?.loginUser;

          if (successCallback) {
            successCallback(accessToken, userData, refreshToken);
          }
        } else {
          form?.setFieldsValue(values);
        }
      },
    });
  };

  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-start">
        <Card className="full-width">
          <Spin spinning={loginLoading} wrapperClassName="full-width">
            <div className="text-center  mb-48">
              <Logo />
            </div>
            <Form
              name="Login"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              size="large"
              form={form}
            >
              <Form.Item
                name="email"
                className="mb-32"
                rules={[{ required, message: MESSAGE.required }, email]}
                normalize={formItemProps?.normalize}
              >
                <Input prefix={<UserOutlined />} placeholder="Email" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ required, message: MESSAGE.required }]}
                normalize={formItemProps?.normalize}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Password"
                />
              </Form.Item>
              {/* commenting for future use */}
              {/* <Form.Item className="text-right mb-8">
                <Link to={ROUTES?.FORGET_PASSWORD}>Forgot password ?</Link>
              </Form.Item> */}
              <Form.Item className=" full-width mb-8 mt-32">
                <Button type="primary" className="full-width" htmlType="submit">
                  Login
                </Button>
              </Form.Item>
              {/* commenting for future use */}
              {/* <Form.Item className="text-center mb-0">
                <Space>
                  Don't have an account yet ?
                  <Button
                    type="link"
                    className="p-0"
                    onClick={() => {
                      navigate(ROUTES?.SIGNUP);
                    }}
                  >
                    Signup
                  </Button>
                </Space>
              </Form.Item> */}
            </Form>
          </Spin>
        </Card>
        <div className="text-center login-copyrights">
          {COMPANY_INFO?.FOOTER_TEXT}
        </div>
      </div>
    </div>
  );
};

export default Login;
