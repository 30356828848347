import { MailOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Modal, Select, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React from 'react';
import { MESSAGE } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import { INVITE_USERS } from '../../../modules/users/graphql/mutations';

const { required, multipleEmails } = formValidatorRules;

export default function InviteModal({
  onCancel = () => {},
  fetchData = () => {},
  ...rest
}) {
  const [form] = useForm();
  const [inviteUsers, { loading }] = useMutation(INVITE_USERS, {
    onCompleted: () => {
      fetchData();
      form.resetFields();
      onCancel();
    },
    onError: () => {},
  });

  const onFinish = ({ emails }) => {
    inviteUsers({ variables: { data: { emails } } });
  };

  return (
    <Modal footer={null} title="Invite Users" onCancel={onCancel} {...rest}>
      <Spin wrapperClassName="full-width" spinning={false}>
        <div className="form-wrapper">
          <Form
            name="auth-form"
            onFinish={onFinish}
            size="large"
            form={form}
            layout="vertical"
            className="invite-email-form"
          >
            <Form.Item
              name="emails"
              rules={[{ required, message: MESSAGE.required }, multipleEmails]}
              className="mt-32 mb-8"
            >
              <Select
                mode="tags"
                size="middle"
                tokenSeparators={[',']}
                placeholder="Write email(s) here..."
                allowClear
                open={false}
                suffixIcon={<MailOutlined />}
              />
            </Form.Item>
            <Form.Item shouldUpdate className="d-flex justify-end mb-0">
              {() => (
                <Button
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  loading={loading}
                  disabled={
                    !form.isFieldsTouched(true) ||
                    !!form
                      ?.getFieldsError()
                      ?.filter(({ errors }) => errors?.length)?.length
                  }
                >
                  Send Invite
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </Modal>
  );
}
