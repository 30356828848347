import { Alert, Button, Divider, Modal } from 'antd';
import React from 'react';

export default function ErrorModal({ callback, ...rest }) {
  return (
    <Modal footer={null} {...rest}>
      <h3>
        We encountered an issue while attempting to process your file. The
        failure may be due to one of the following reasons:
      </h3>
      <ul>
        <li>
          <strong>Invalid URL/Link:</strong> The provided link may not be
          correct or functional.
        </li>
        <li>
          <strong>Access Denied:</strong> Our system might not have the
          necessary permissions to access the file.
        </li>
        <li>
          <strong>Technical Issue(s):</strong> There could be a technical glitch
          affecting the file processing.
        </li>
      </ul>
      <Divider />
      <Alert
        message="Please review the possible causes and attempt to upload your file again."
        type="warning"
        action={
          <Button
            size="small"
            type="primary"
            className="ml-16"
            onClick={callback}
          >
            Upload
          </Button>
        }
      />
    </Modal>
  );
}
