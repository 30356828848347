import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation loginUser($data: LoginUserInput!) {
    loginUser(data: $data) {
      message
      accessToken
      refreshToken
      data {
        firstName
        profileImage
        lastName
        email
        roles
      }
    }
  }
`;

export const SIGNUP = gql`
  mutation emailPasswordSignUp($data: EmailPasswordSignUpData!) {
    emailPasswordSignUp(data: $data) {
      message
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation emailPasswordForgotPassword(
    $data: EmailPasswordForgotPasswordData!
  ) {
    emailPasswordForgotPassword(data: $data) {
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation emailPasswordResetPassword($data: EmailPasswordResetPasswordData!) {
    emailPasswordResetPassword(data: $data) {
      message
    }
  }
`;

export const VERIFY_TOKEN = gql`
  mutation emailPasswordVerifyResetToken($token: String!) {
    emailPasswordVerifyResetToken(data: { token: $token }) {
      message
      data {
        isValidToken
      }
    }
  }
`;

export const UPDATE_CURRENT_USER = gql`
  mutation updateUser($data: UpdateUserInput!) {
    updateUser(data: $data) {
      message
      data {
        id
        email
        profileImage
        firstName
        lastName
      }
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken($data: RefreshTokenInput!) {
    refreshToken(data: $data) {
      accessToken
      user {
        id
        email
        profileImage
        firstName
        lastName
      }
    }
  }
`;
