import { FolderOutlined, SwapOutlined, UserOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { MODULES, ROUTES } from '../../../common/constants';
import useRouter from '../../../hooks/useRouter';

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const menuItems = [
  // commenting for future use
  // getItem(MODULES?.DASHBOARD, ROUTES?.MAIN, <AppstoreOutlined />),
  getItem(MODULES?.CONTENT, ROUTES?.MAIN, <FolderOutlined />),
  getItem(MODULES?.USERS, ROUTES?.USERS, <UserOutlined />),
  getItem(
    MODULES?.IMPORT_EXPORT,
    ROUTES?.IMPORT_EXPORT,
    <SwapOutlined rotate={90} />,
  ),
];

function Sidebar({ setActive }) {
  const {
    navigate,
    location: { pathname },
  } = useRouter();

  const onMenuSelect = (e) => {
    setActive(false);
    navigate(e?.key);
  };

  return (
    <div className="side-bar">
      <Menu
        theme="light"
        mode="inline"
        selectedKeys={[`/${pathname?.split('/')?.[1]}`]}
        defaultSelectedKeys={[ROUTES?.MAIN]}
        onSelect={onMenuSelect}
        onClick={onMenuSelect}
        items={menuItems}
      />
    </div>
  );
}

export default Sidebar;
