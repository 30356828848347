import { InboxOutlined } from '@ant-design/icons';
import { Modal, Spin } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../../../common/api';
import { COMMON_FILE_UPLOAD_TYPES } from '../../../common/constants';
import { beforeFileUpload } from '../../../common/utils';

export default function UploadModal({
  onCancel = () => {},
  fetchData = () => {},
  ...rest
}) {
  const [isUploading, setIsUploading] = useState(false);
  const SAMPLE_CSV_LINK = process.env.REACT_APP_SAMPLE_CSV_LINK;

  const handleUpload = async (file) => {
    try {
      setIsUploading(true);
      // eslint-disable-next-line no-undef
      const formData = new FormData();
      formData?.append('file', file);
      const response = await api('/upload-asset', {
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response) {
        fetchData();
        onCancel();
      }
    } catch (error) {
      return error;
    } finally {
      setIsUploading(false);
    }
  };

  const props = {
    name: 'file',
    multiple: false,
    beforeUpload: () => false,
    fileList: [],
    accept: COMMON_FILE_UPLOAD_TYPES,
    onChange: (info) => {
      if (beforeFileUpload(info?.file)) {
        handleUpload(info?.file);
      }
    },
  };

  return (
    <Modal footer={false} title="Upload Files" onCancel={onCancel} {...rest}>
      <Spin spinning={isUploading}>
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Support for a single file upload. Only files with the following
            extensions are accepted: CSV and PDF.
          </p>
        </Dragger>
      </Spin>
      <p className="text-center">
        <Link to={SAMPLE_CSV_LINK}>
          <strong className="text-underline">Click here</strong> to download
          sample CSV file.
        </Link>
      </p>
    </Modal>
  );
}
