import { useLazyQuery } from '@apollo/client';
import { Badge, Space, Table, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { camelCase, startCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  FILE_STATUS,
  FILTERS,
  LIMIT,
  SORT_BY,
  SORT_ON,
} from '../../common/constants';
import '../index.less';
import { GET_IMPORT_EXPORT_LIST } from './graphql/queries';

dayjs.extend(utc);

const { Link } = Typography;

export default function ImportExportList() {
  const [dataList, setDataList] = useState([]);
  const initialFilters = {
    sort: [
      {
        sortBy: SORT_BY.DESC,
        sortOn: SORT_ON.createdAt,
      },
    ],
    filter: {
      limit: LIMIT,
      skip: 0,
      current: 1,
    },
  };
  const [tableParams, setTableParams] = useState(initialFilters);

  const [fetchList, { loading, data }] = useLazyQuery(GET_IMPORT_EXPORT_LIST, {
    onCompleted: (res) => {
      setDataList(res?.listImportedFile?.data || []);
    },
    onError: () => {},
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const payLoad = tableParams;
    delete payLoad?.filter?.current;
    fetchList({
      variables: payLoad,
    });
  }, [tableParams]);

  const handleTableChange = (pagination, filters) => {
    const { current, pageSize } = pagination;
    setTableParams({
      ...tableParams,
      filter: {
        ...tableParams?.filter,
        limit: pageSize,
        skip: current * pageSize - pageSize,
        ...filters,
      },
    });
  };

  const renderTags = ({ status }) => {
    switch (status) {
      case FILE_STATUS?.FAILED:
        return (
          <Tag color="error">{startCase(camelCase(FILE_STATUS?.FAILED))}</Tag>
        );
      case FILE_STATUS?.PROCESSING:
        return (
          <Tag color="processing">
            {startCase(camelCase(FILE_STATUS?.PROCESSING))}
          </Tag>
        );
      case FILE_STATUS?.COMPLETED:
        return (
          <Tag color="success">
            {startCase(camelCase(FILE_STATUS?.COMPLETED))}
          </Tag>
        );
      case FILE_STATUS?.PARTIALLY_COMPLETED:
        return (
          <Tag color="warning">
            {startCase(camelCase(FILE_STATUS?.PARTIALLY_COMPLETED))}
          </Tag>
        );
      default:
        return '-';
    }
  };

  const renderLink = (name, link) => {
    if (name) {
      return (
        <Link href={link} target="_blank">
          {name}
        </Link>
      );
    }
    return '-';
  };

  const columns = [
    {
      title: 'Source File',
      dataIndex: 'sourceFileName',
      key: 'sourceFileName',
      render: (_, record) =>
        renderLink(record?.sourceFileName, record?.sourceFileUrl),
    },
    {
      title: 'Failed File',
      dataIndex: 'failedFileName',
      key: 'failedFileName',
      render: (_, record) =>
        renderLink(record?.failedFileName, record?.failedFileUrl),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, record) => renderTags(record),
      filters: FILTERS.FILE_STATUS,
    },

    {
      title: 'Created Count',
      dataIndex: 'createdCount',
      key: 'createdCount',
      width: '5%',
      render: (value) => value ?? 0,
    },
    {
      title: 'Updated Count',
      dataIndex: 'updatedCount',
      key: 'updatedCount',
      width: '5%',
      render: (value) => value ?? 0,
    },
    {
      title: 'Failed Count',
      dataIndex: 'failedCount',
      key: 'failedCount',
      width: '5%',
      render: (value) => value ?? 0,
    },
    {
      title: 'Total',
      dataIndex: 'totalCount',
      key: 'totalCount',
      width: '5%',
      render: (value) => value ?? 0,
    },
  ];

  return (
    <div className="content-wrapper">
      <Space direction="vertical" className="d-flex" size={4}>
        <div className="d-flex justify-between align-center">
          <h2>
            Import/Export{' '}
            <Badge
              count={data?.listImportedFile?.count}
              showZeros
              color="var(--color-primary)"
            />
          </h2>
        </div>
        <div className="responsive-table">
          <Table
            columns={columns}
            dataSource={dataList}
            pagination={{
              position: ['bottomCenter'],
              total: data?.listImportedFile?.count,
              current: tableParams.filter.current,
              pageSize: tableParams.filter.limit,
              responsive: true,
            }}
            loading={loading}
            className="content-list-table"
            tableLayout="auto"
            onChange={handleTableChange}
            locale={{
              filterConfirm: 'Save',
              emptyText: 'No data available',
            }}
          />
        </div>
      </Space>
    </div>
  );
}
