import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_ASSETS = gql`
  query assets($filter: AssetsFilter!, $sort: [ListAssetsSort!]!) {
    assets(filter: $filter, sort: $sort) {
      count
      data {
        name
        status
        createdAt
        url
        id
        type
        reason
      }
    }
  }
`;

export const LIST_USER = gql`
  query listUser($filter: ListUserFilter!, $sort: [ListUserSort!]!) {
    listUser(filter: $filter, sort: $sort) {
      count
      data {
        id
        email
        firstName
        lastName
        fullName
        profileImage
        roles
        isActive
        lastSessionTime
        status
        linkExpireMessage
        lastSessionTime
        createdAt
      }
    }
  }
`;

export const ResendInvitation = gql`
  mutation resendInvitation($where: ResendInvitationInput!) {
    resendInvitation(where: $where) {
      message
    }
  }
`;
