import axios from 'axios';
import { messageContext } from '../components/AppContextHolder';
import history from '../historyData';
import { ROUTES, TOKEN } from './constants';

// eslint-disable-next-line no-undef
const token = localStorage?.getItem(TOKEN);

const api = axios?.create({
  baseURL: process.env.REACT_APP_SERVER_REST_URL,
  headers: {
    ...(token && {
      authorization: `Bearer ${token}`,
    }),
  },
});

const toast = ({ message: content, type }) => {
  messageContext?.destroy();
  switch (type) {
    case 'info':
      messageContext?.info(content);
      break;
    case 'success':
      messageContext?.success(content);
      break;
    case 'warning':
      messageContext?.warning(content);
      break;
    case 'error':
      messageContext?.error(content);
      break;
    default:
      break;
  }
};

api?.interceptors?.request?.use(
  (config) => config,
  (err) => Promise?.reject(err),
);

api?.interceptors?.response?.use(
  (response) => {
    if (response?.config?.method !== 'get')
      toast({
        message: response?.data?.message || 'Operation successful!',
        type: response?.status === 'ERROR' ? 'error' : 'success',
      });
    return response?.data;
  },
  (error) => {
    toast({
      message: error?.response?.data?.message || 'Something went wrong!',
      type: 'error',
    });
    if (error?.response?.status === 401) {
      history?.push(ROUTES?.LOGIN);
    }
    return Promise?.reject(error);
  },
);

export default api;
