import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const DELETE_ASSET = gql`
  mutation deleteAsset($where: DeleteAssetWhereInput!) {
    deleteAsset(where: $where) {
      message
    }
  }
`;
