import React from 'react';

export const Logo = ({ height = 30, width = 200 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 174 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.3257 30C21.8698 30 19.0757 27.2059 19.0757 23.75C19.0757 20.2941 21.8698 17.4265 25.3993 17.4265C28.8551 17.4265 31.6493 20.2206 31.6493 23.6765C31.6493 27.2059 28.8551 30 25.3257 30Z"
      fill="#DF3C26"
    />
    <path
      d="M31.6493 17.1323C31.6493 14.2647 34.0022 11.9853 36.7963 11.9853C39.664 11.9853 42.0169 14.2647 42.0169 17.2059C42.0169 20.0735 39.664 22.4265 36.7963 22.4265C33.8552 22.3529 31.6493 20 31.6493 17.1323Z"
      fill="#DF3C26"
    />
    <path
      d="M19.1493 17.2058C19.0757 20.0735 16.7963 22.3529 14.0022 22.3529C11.061 22.3529 8.78162 19.9264 8.78162 16.9852C8.85515 14.1911 11.2081 11.8382 14.0757 11.9117C16.8699 11.9852 19.1493 14.3382 19.1493 17.2058Z"
      fill="#DF3C26"
    />
    <path
      d="M4.81104 6.98529C7.16398 7.05882 9.00222 8.97058 9.00222 11.3235C8.92869 13.6765 7.01692 15.5147 4.66398 15.4412C2.23751 15.3676 0.472807 13.4559 0.546336 11.0294C0.619866 8.74999 2.53163 6.91176 4.81104 6.98529Z"
      fill="#DF3C26"
    />
    <path
      d="M41.2081 11.25C41.2081 8.89705 43.0463 6.98529 45.3993 6.98529C47.7522 6.98529 49.664 8.89705 49.664 11.25C49.664 13.5294 47.7522 15.4412 45.4728 15.4412C43.1199 15.4412 41.2081 13.6029 41.2081 11.25Z"
      fill="#DF3C26"
    />
    <path
      d="M11.2081 4.33823C12.8258 4.33823 14.0758 5.58823 14.0758 7.20587C14.0758 8.82352 12.7522 10.0735 11.1346 10.0735C9.59046 10.0735 8.34046 8.74999 8.34046 7.20587C8.34046 5.5147 9.66399 4.2647 11.2081 4.33823Z"
      fill="#DF3C26"
    />
    <path
      d="M38.8551 10C37.311 10 35.9875 8.75002 35.9875 7.2059C35.9875 5.66178 37.2375 4.33826 38.8551 4.33826C40.4728 4.33826 41.7963 5.58825 41.7228 7.2059C41.7228 8.75002 40.4728 10 38.8551 10Z"
      fill="#DF3C26"
    />
    <path
      d="M18.9287 4.70585C18.9287 5.95585 17.8993 7.05879 16.5758 6.98526C15.3258 6.98526 14.2228 5.88231 14.2963 4.63232C14.2963 3.38232 15.3993 2.35291 16.6493 2.35291C17.8993 2.42643 18.9287 3.45585 18.9287 4.70585Z"
      fill="#DF3C26"
    />
    <path
      d="M35.7669 4.70585C35.7669 5.95585 34.664 7.05879 33.414 6.98526C32.164 6.98526 31.1346 5.95584 31.1346 4.63232C31.1346 3.38232 32.164 2.35291 33.4875 2.35291C34.7375 2.42643 35.7669 3.45585 35.7669 4.70585Z"
      fill="#DF3C26"
    />
    <path
      d="M20.9875 4.48527C19.9581 4.48527 19.2228 3.74997 19.2228 2.72056C19.2228 1.61762 19.9581 0.882324 20.9875 0.882324C22.0169 0.882324 22.7522 1.61762 22.8257 2.64703C22.8257 3.67644 22.0169 4.48527 20.9875 4.48527Z"
      fill="#DF3C26"
    />
    <path
      d="M30.6199 2.72056C30.6199 3.74997 29.8846 4.48527 28.8552 4.48527C27.8258 4.48527 27.0169 3.67644 27.0169 2.64703C27.0169 1.61762 27.8258 0.882324 28.8552 0.882324C29.8846 0.882324 30.6199 1.69115 30.6199 2.72056Z"
      fill="#DF3C26"
    />
    <path
      d="M24.9581 0C25.7669 0 26.5022 0.661765 26.5022 1.47059C26.5022 2.35294 25.8405 3.01471 24.9581 3.01471C24.0757 3.01471 23.414 2.35294 23.414 1.47059C23.414 0.661765 24.0757 0 24.9581 0Z"
      fill="#DF3C26"
    />
    <path
      d="M55.7294 13.3519C55.8602 12.7633 55.991 12.1747 56.1218 11.586C57.3645 7.00778 61.1579 4.45703 65.9978 4.84945C66.5864 4.91486 67.175 4.98026 67.7637 5.11107C68.8755 5.30728 69.922 5.89591 71.0993 5.43809C71.0993 7.00777 71.0993 8.38125 71.0993 10.0163C70.8376 9.88553 70.576 9.82013 70.3798 9.62392C68.6139 8.11964 66.5864 7.59641 64.2973 7.98883C62.5968 8.31585 61.2887 9.23149 60.5039 10.8012C59.1958 13.3519 59.1958 15.9681 60.5039 18.5188C62.5314 22.443 68.1561 22.3776 70.7068 19.7615C70.903 19.5653 71.0338 19.1728 71.4917 19.4999C71.2955 20.8079 71.1647 22.116 70.903 23.4895C70.903 23.6203 70.7722 23.8165 70.6414 23.8165C66.7826 25.0592 62.9892 25.19 59.4574 22.8354C57.2991 21.3966 56.1872 19.2382 55.7948 16.6875C55.7948 16.5567 55.7294 16.4259 55.664 16.2951C55.7294 15.2486 55.7294 14.2676 55.7294 13.3519Z"
      fill="black"
    />
    <path
      d="M114.396 25.19C113.677 23.6203 113.023 22.0507 112.304 20.481C110.799 17.1454 109.23 13.8098 107.725 10.4742C107.267 9.49317 106.81 8.44671 105.698 7.98889C105.567 7.92348 105.502 7.53106 105.436 7.26945C107.66 7.26945 109.753 7.26945 111.911 7.26945C111.911 7.53106 111.911 7.72727 111.911 7.92348C111.126 8.2505 110.995 8.44671 111.257 9.16615C112.434 12.1093 113.677 14.9871 114.92 17.8648C114.92 17.8648 114.92 17.8648 115.05 17.9302C115.508 16.8838 115.966 15.9027 116.359 14.9217C117.143 13.0904 117.928 11.2591 118.713 9.42776C118.975 8.83913 119.236 8.2505 118.386 7.92348C118.255 7.85808 118.321 7.46565 118.255 7.20404C120.217 7.20404 122.049 7.20404 123.945 7.20404C124.076 7.59646 123.945 7.85808 123.618 8.11969C123.03 8.64292 122.441 9.23156 122.049 9.951C120.937 12.1747 119.89 14.5292 118.844 16.8184C117.667 19.3691 116.489 21.9853 115.312 24.536C115.247 24.7322 115.181 24.9284 115.116 25.1246C114.92 25.19 114.658 25.19 114.396 25.19Z"
      fill="black"
    />
    <path
      d="M138.138 16.7529C133.952 16.7529 129.832 16.7529 125.711 16.7529C125.777 19.4999 127.085 21.2004 129.505 21.5928C131.859 21.9852 134.083 21.462 135.98 19.9577C136.372 19.6961 136.634 19.5653 137.091 19.8269C136.961 21.0042 136.83 22.2468 136.634 23.4241C136.634 23.5549 136.503 23.6857 136.372 23.7511C133.429 24.9284 130.42 25.2554 127.412 24.4705C125.188 23.8819 123.684 22.3122 122.768 20.2847C121.525 17.3416 121.525 14.2676 122.964 11.3898C124.142 8.90449 126.234 7.66182 128.981 7.2694C130.486 7.07319 131.925 7.13859 133.363 7.66182C136.437 8.77368 138.007 11.3898 138.203 14.3984C138.203 15.1178 138.138 15.8373 138.138 16.7529ZM134.475 13.8752C134.214 11.3898 132.513 9.95095 129.962 10.0164C127.804 10.0818 125.907 11.8477 125.777 13.8752C128.654 13.8752 131.532 13.8752 134.475 13.8752Z"
      fill="black"
    />
    <path
      d="M171.428 16.7529C167.177 16.7529 163.057 16.7529 158.871 16.7529C158.936 19.3037 160.114 21.0696 162.206 21.5274C164.757 22.116 167.112 21.6582 169.139 20.0231C169.466 19.7615 169.793 19.6307 170.251 19.8923C170.12 21.0696 169.99 22.3122 169.859 23.4895C169.859 23.6203 169.662 23.7511 169.532 23.7511C166.523 24.9938 163.449 25.3208 160.31 24.4052C158.282 23.8165 156.843 22.3776 155.993 20.4809C154.62 17.4724 154.62 14.3984 156.059 11.3898C157.236 8.9045 159.329 7.59642 162.076 7.204C164.038 6.94239 165.869 7.204 167.57 8.11965C169.662 9.29692 170.774 11.1936 171.167 13.4828C171.428 14.5292 171.428 15.5757 171.428 16.7529ZM167.7 13.8752C167.373 11.5206 165.869 9.88555 163.188 10.0164C161.029 10.1472 159.067 11.9131 159.002 13.8752C161.879 13.8752 164.757 13.8752 167.7 13.8752Z"
      fill="black"
    />
    <path
      d="M72.0803 15.9027C72.1457 13.4174 72.7997 11.1282 74.631 9.36233C75.8737 8.18506 77.378 7.53103 79.0131 7.26941C81.106 6.87699 83.1989 7.0732 85.161 7.92345C87.5809 9.03531 89.0198 10.8666 89.6084 13.4828C90.1971 16.0989 90.0009 18.5842 88.6274 20.9388C87.3193 23.1625 85.2264 24.3398 82.7411 24.7322C80.5173 25.0592 78.2936 24.863 76.3315 23.7511C73.977 22.443 72.7343 20.3501 72.2765 17.7994C72.1457 17.1454 72.1457 16.4913 72.0803 15.9027ZM75.7429 16.0989C75.6775 19.1075 77.378 21.5274 80.7136 21.7236C83.0027 21.789 85.0956 20.9388 85.9458 18.2572C86.2729 17.2108 86.3383 16.0989 86.2729 14.987C86.0766 13.0249 85.2264 11.3898 83.3297 10.6704C81.3022 9.88556 79.2747 10.0164 77.5742 11.4552C76.1353 12.5671 75.7429 14.1368 75.7429 16.0989Z"
      fill="black"
    />
    <path
      d="M100.858 24.2089C100.858 23.9473 100.858 23.7511 100.858 23.4895C102.231 23.3587 102.362 22.3776 102.428 21.3312C102.493 18.7804 102.493 16.2297 102.558 13.7444C102.558 13.2865 102.493 12.8941 102.362 12.4363C101.97 10.932 101.185 10.3434 99.6806 10.278C96.7375 10.0818 95.2332 12.1747 95.1678 14.333C95.1024 16.8183 95.2332 19.3037 95.2986 21.789C95.2986 22.5739 95.6256 23.2279 96.4759 23.4241C96.6721 23.4895 96.7375 23.8819 96.9337 24.2089C94.3829 24.2089 92.0938 24.2089 89.7393 24.2089C89.7393 24.0127 89.7393 23.7511 89.7393 23.4895C89.8701 23.4241 90.0009 23.3587 90.1971 23.3587C90.7203 23.2279 91.1128 22.9009 91.1782 22.3776C91.309 21.2658 91.4398 20.1539 91.4398 19.0421C91.4398 16.3605 91.4398 13.679 91.3744 10.9974C91.3744 10.4742 91.2436 9.95095 91.1128 9.49313C90.9819 8.9699 90.7203 8.64289 90.1971 8.57748C89.6739 8.51208 89.6085 8.18506 89.7393 7.66183C90.1971 7.66183 90.6549 7.66183 91.1782 7.66183C92.0938 7.66183 93.0095 7.66183 93.9905 7.66183C94.3829 7.66183 94.5791 7.72723 94.5791 8.18506C94.5791 8.57748 94.71 8.9699 94.8408 9.49313C95.8218 8.31586 96.9991 7.59643 98.438 7.33481C100.073 7.00779 101.708 7.0732 103.278 7.79264C105.109 8.64288 106.025 10.0818 106.09 12.1093C106.156 14.7908 106.156 17.5378 106.221 20.2193C106.221 20.7425 106.286 21.3312 106.352 21.8544C106.483 22.7701 106.875 23.4241 107.921 23.5549C107.921 23.8165 107.921 24.0127 107.921 24.2743C105.502 24.2089 103.212 24.2089 100.858 24.2089Z"
      fill="black"
    />
    <path
      d="M144.875 24.2743C142.324 24.2743 140.035 24.2743 137.68 24.2743C137.68 24.0127 137.68 23.8165 137.68 23.5549C139.315 23.4241 139.315 22.116 139.315 21.0042C139.381 17.6686 139.315 14.333 139.315 10.9974C139.315 10.605 139.25 10.2126 139.184 9.88555C139.054 9.0353 138.53 8.57748 137.68 8.44667C137.68 8.18505 137.68 7.98885 137.68 7.66183C139.184 7.66183 140.689 7.66183 142.193 7.66183C142.324 7.66183 142.455 7.85804 142.52 7.98885C142.651 8.44667 142.716 8.90449 142.782 9.42772C143.632 8.38127 144.678 7.66183 145.921 7.40021C147.818 6.94239 149.649 6.94239 151.415 7.85804C153.05 8.70828 153.966 10.0818 154.031 11.9785C154.096 14.5292 154.096 17.0799 154.162 19.6307C154.162 20.3501 154.227 21.0042 154.293 21.7236C154.358 22.7047 154.75 23.4241 155.862 23.4895C155.862 23.7511 155.862 23.9473 155.862 24.2089C153.508 24.2089 151.219 24.2089 148.864 24.2089C148.864 24.0127 148.864 23.7511 148.864 23.4895C150.303 23.3587 150.368 22.2468 150.434 21.135C150.499 18.715 150.499 16.2297 150.565 13.8098C150.565 13.3519 150.499 12.9595 150.434 12.5017C150.107 10.9974 149.257 10.3434 147.687 10.278C144.94 10.0818 143.239 12.0439 143.239 14.2676C143.174 16.7529 143.305 19.3037 143.305 21.789C143.305 22.6393 143.632 23.2279 144.548 23.4895C144.548 23.4895 144.678 23.8819 144.875 24.2743Z"
      fill="black"
    />
    <path
      d="M171.559 7.46564C171.69 7.46564 171.821 7.46564 171.952 7.46564C172.148 7.46564 172.344 7.46564 172.409 7.59645C172.475 7.66185 172.54 7.79266 172.54 7.92347C172.54 8.11968 172.409 8.25048 172.279 8.31589C172.409 8.38129 172.475 8.44669 172.475 8.5775C172.54 8.77371 172.54 8.96992 172.606 9.03532H172.279C172.279 8.96992 172.213 8.83912 172.148 8.6429C172.082 8.44669 172.017 8.38129 171.886 8.38129H171.821V9.03532H171.494V7.46564H171.559ZM171.886 8.11967H172.017C172.148 8.11967 172.279 8.05427 172.279 7.85806C172.279 7.72725 172.213 7.66185 172.017 7.66185C171.952 7.66185 171.886 7.66185 171.886 7.66185V8.11967Z"
      fill="black"
    />
    <path
      d="M172.017 7.1386C172.606 7.1386 173.129 7.59643 173.129 8.25047C173.129 8.8391 172.671 9.36233 172.017 9.36233C171.363 9.36233 170.905 8.9045 170.905 8.25047C170.905 7.59643 171.428 7.1386 172.017 7.1386ZM172.017 6.81158C171.232 6.81158 170.578 7.46562 170.578 8.25047C170.578 9.03531 171.232 9.68934 172.017 9.68934C172.802 9.68934 173.456 9.03531 173.456 8.25047C173.456 7.46562 172.802 6.81158 172.017 6.81158Z"
      fill="black"
    />
  </svg>
);

export const LogoIcon = ({ height = 30, width = 50 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 50 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.3257 30C21.8698 30 19.0757 27.2059 19.0757 23.75C19.0757 20.2941 21.8698 17.4265 25.3993 17.4265C28.8551 17.4265 31.6493 20.2206 31.6493 23.6765C31.6493 27.2059 28.8551 30 25.3257 30Z"
      fill="#DF3C26"
    />
    <path
      d="M31.6493 17.1323C31.6493 14.2647 34.0022 11.9853 36.7963 11.9853C39.664 11.9853 42.0169 14.2647 42.0169 17.2059C42.0169 20.0735 39.664 22.4265 36.7963 22.4265C33.8552 22.3529 31.6493 20 31.6493 17.1323Z"
      fill="#DF3C26"
    />
    <path
      d="M19.1493 17.2058C19.0757 20.0735 16.7963 22.3529 14.0022 22.3529C11.061 22.3529 8.78162 19.9264 8.78162 16.9852C8.85515 14.1911 11.2081 11.8382 14.0757 11.9117C16.8699 11.9852 19.1493 14.3382 19.1493 17.2058Z"
      fill="#DF3C26"
    />
    <path
      d="M4.81104 6.98529C7.16398 7.05882 9.00222 8.97058 9.00222 11.3235C8.92869 13.6765 7.01692 15.5147 4.66398 15.4412C2.23751 15.3676 0.472807 13.4559 0.546336 11.0294C0.619866 8.74999 2.53163 6.91176 4.81104 6.98529Z"
      fill="#DF3C26"
    />
    <path
      d="M41.2081 11.25C41.2081 8.89705 43.0463 6.98529 45.3993 6.98529C47.7522 6.98529 49.664 8.89705 49.664 11.25C49.664 13.5294 47.7522 15.4412 45.4728 15.4412C43.1199 15.4412 41.2081 13.6029 41.2081 11.25Z"
      fill="#DF3C26"
    />
    <path
      d="M11.2081 4.33823C12.8258 4.33823 14.0758 5.58823 14.0758 7.20587C14.0758 8.82352 12.7522 10.0735 11.1346 10.0735C9.59046 10.0735 8.34046 8.74999 8.34046 7.20587C8.34046 5.5147 9.66399 4.2647 11.2081 4.33823Z"
      fill="#DF3C26"
    />
    <path
      d="M38.8551 10C37.311 10 35.9875 8.75002 35.9875 7.2059C35.9875 5.66178 37.2375 4.33826 38.8551 4.33826C40.4728 4.33826 41.7963 5.58825 41.7228 7.2059C41.7228 8.75002 40.4728 10 38.8551 10Z"
      fill="#DF3C26"
    />
    <path
      d="M18.9287 4.70585C18.9287 5.95585 17.8993 7.05879 16.5758 6.98526C15.3258 6.98526 14.2228 5.88231 14.2963 4.63232C14.2963 3.38232 15.3993 2.35291 16.6493 2.35291C17.8993 2.42643 18.9287 3.45585 18.9287 4.70585Z"
      fill="#DF3C26"
    />
    <path
      d="M35.7669 4.70585C35.7669 5.95585 34.664 7.05879 33.414 6.98526C32.164 6.98526 31.1346 5.95584 31.1346 4.63232C31.1346 3.38232 32.164 2.35291 33.4875 2.35291C34.7375 2.42643 35.7669 3.45585 35.7669 4.70585Z"
      fill="#DF3C26"
    />
    <path
      d="M20.9875 4.48527C19.9581 4.48527 19.2228 3.74997 19.2228 2.72056C19.2228 1.61762 19.9581 0.882324 20.9875 0.882324C22.0169 0.882324 22.7522 1.61762 22.8257 2.64703C22.8257 3.67644 22.0169 4.48527 20.9875 4.48527Z"
      fill="#DF3C26"
    />
    <path
      d="M30.6199 2.72056C30.6199 3.74997 29.8846 4.48527 28.8552 4.48527C27.8258 4.48527 27.0169 3.67644 27.0169 2.64703C27.0169 1.61762 27.8258 0.882324 28.8552 0.882324C29.8846 0.882324 30.6199 1.69115 30.6199 2.72056Z"
      fill="#DF3C26"
    />
    <path
      d="M24.9581 0C25.7669 0 26.5022 0.661765 26.5022 1.47059C26.5022 2.35294 25.8405 3.01471 24.9581 3.01471C24.0757 3.01471 23.414 2.35294 23.414 1.47059C23.414 0.661765 24.0757 0 24.9581 0Z"
      fill="#DF3C26"
    />
  </svg>
);

export const LogoText = ({ height = 22, width = 120 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 119 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.729405 9.35192C0.860213 8.76329 0.99102 8.17466 1.12183 7.58603C2.3645 3.00778 6.15791 0.457033 10.9978 0.849454C11.5864 0.914858 12.175 0.980263 12.7637 1.11107C13.8755 1.30728 14.922 1.89591 16.0993 1.43809C16.0993 3.00777 16.0993 4.38125 16.0993 6.01634C15.8376 5.88553 15.576 5.82013 15.3798 5.62392C13.6139 4.11964 11.5864 3.59641 9.29728 3.98883C7.59678 4.31585 6.28871 5.23149 5.50387 6.80118C4.1958 9.35192 4.1958 11.9681 5.50387 14.5188C7.53138 18.443 13.1561 18.3776 15.7068 15.7615C15.903 15.5653 16.0338 15.1728 16.4917 15.4999C16.2955 16.8079 16.1647 18.116 15.903 19.4895C15.903 19.6203 15.7722 19.8165 15.6414 19.8165C11.7826 21.0592 7.98921 21.19 4.45741 18.8354C2.29909 17.3966 1.18723 15.2382 0.794809 12.6875C0.794809 12.5567 0.729405 12.4259 0.664001 12.2951C0.729405 11.2486 0.729405 10.2676 0.729405 9.35192Z"
      fill="black"
    />
    <path
      d="M59.3964 21.19C58.677 19.6203 58.0229 18.0507 57.3035 16.481C55.7992 13.1454 54.2295 9.8098 52.7252 6.47422C52.2674 5.49317 51.8096 4.44671 50.6977 3.98889C50.5669 3.92348 50.5015 3.53106 50.4361 3.26945C52.6598 3.26945 54.7528 3.26945 56.9111 3.26945C56.9111 3.53106 56.9111 3.72727 56.9111 3.92348C56.1262 4.2505 55.9954 4.44671 56.257 5.16615C57.4343 8.10931 58.677 10.9871 59.9196 13.8648C59.9196 13.8648 59.9196 13.8648 60.0505 13.9302C60.5083 12.8838 60.9661 11.9027 61.3585 10.9217C62.1434 9.09037 62.9282 7.25907 63.7131 5.42776C63.9747 4.83913 64.2363 4.2505 63.386 3.92348C63.2552 3.85808 63.3206 3.46565 63.2552 3.20404C65.2173 3.20404 67.0486 3.20404 68.9453 3.20404C69.0762 3.59646 68.9453 3.85808 68.6183 4.11969C68.0297 4.64292 67.4411 5.23156 67.0486 5.951C65.9368 8.17472 64.8903 10.5292 63.8439 12.8184C62.6666 15.3691 61.4893 17.9853 60.3121 20.536C60.2467 20.7322 60.1813 20.9284 60.1159 21.1246C59.9196 21.19 59.658 21.19 59.3964 21.19Z"
      fill="black"
    />
    <path
      d="M83.1379 12.7529C78.9521 12.7529 74.8317 12.7529 70.7113 12.7529C70.7767 15.4999 72.0847 17.2004 74.5047 17.5928C76.8592 17.9852 79.0829 17.462 80.9796 15.9577C81.372 15.6961 81.6337 15.5653 82.0915 15.8269C81.9607 17.0042 81.8299 18.2468 81.6337 19.4241C81.6337 19.5549 81.5029 19.6857 81.3721 19.7511C78.4289 20.9284 75.4203 21.2554 72.4118 20.4705C70.188 19.8819 68.6837 18.3122 67.7681 16.2847C66.5254 13.3416 66.5254 10.2676 67.9643 7.38983C69.1416 4.90449 71.2345 3.66182 73.9814 3.2694C75.4857 3.07319 76.9246 3.13859 78.3635 3.66182C81.4374 4.77368 83.0071 7.38983 83.2034 10.3984C83.2034 11.1178 83.1379 11.8373 83.1379 12.7529ZM79.4753 9.87517C79.2137 7.38983 77.5132 5.95095 74.9625 6.01635C72.8042 6.08176 70.9075 7.84765 70.7767 9.87517C73.6544 9.87517 76.5322 9.87517 79.4753 9.87517Z"
      fill="black"
    />
    <path
      d="M116.428 12.7529C112.177 12.7529 108.057 12.7529 103.871 12.7529C103.936 15.3037 105.114 17.0696 107.206 17.5274C109.757 18.116 112.112 17.6582 114.139 16.0231C114.466 15.7615 114.793 15.6307 115.251 15.8923C115.12 17.0696 114.99 18.3122 114.859 19.4895C114.859 19.6203 114.662 19.7511 114.532 19.7511C111.523 20.9938 108.449 21.3208 105.31 20.4052C103.282 19.8165 101.843 18.3776 100.993 16.4809C99.6197 13.4724 99.6196 10.3984 101.059 7.38983C102.236 4.9045 104.329 3.59642 107.076 3.204C109.038 2.94239 110.869 3.204 112.57 4.11965C114.662 5.29692 115.774 7.19362 116.167 9.48275C116.428 10.5292 116.428 11.5757 116.428 12.7529ZM112.7 9.87517C112.373 7.52064 110.869 5.88555 108.188 6.01636C106.029 6.14716 104.067 7.91306 104.002 9.87517C106.879 9.87517 109.757 9.87517 112.7 9.87517Z"
      fill="black"
    />
    <path
      d="M17.0803 11.9027C17.1457 9.41735 17.7997 7.12823 19.631 5.36233C20.8737 4.18506 22.378 3.53103 24.0131 3.26941C26.106 2.87699 28.1989 3.0732 30.161 3.92345C32.5809 5.03531 34.0198 6.86661 34.6084 9.48276C35.1971 12.0989 35.0009 14.5842 33.6274 16.9388C32.3193 19.1625 30.2264 20.3398 27.7411 20.7322C25.5173 21.0592 23.2936 20.863 21.3315 19.7511C18.977 18.443 17.7343 16.3501 17.2765 13.7994C17.1457 13.1454 17.1457 12.4913 17.0803 11.9027ZM20.7429 12.0989C20.6775 15.1075 22.378 17.5274 25.7136 17.7236C28.0027 17.789 30.0956 16.9388 30.9458 14.2572C31.2729 13.2108 31.3383 12.0989 31.2729 10.987C31.0766 9.02493 30.2264 7.38984 28.3297 6.6704C26.3022 5.88556 24.2747 6.01636 22.5742 7.45524C21.1353 8.5671 20.7429 10.1368 20.7429 12.0989Z"
      fill="black"
    />
    <path
      d="M45.8579 20.2089C45.8579 19.9473 45.8579 19.7511 45.8579 19.4895C47.2314 19.3587 47.3622 18.3776 47.4276 17.3312C47.493 14.7804 47.493 12.2297 47.5584 9.74436C47.5584 9.28654 47.493 8.89412 47.3622 8.43629C46.9698 6.93201 46.1849 6.34338 44.6806 6.27798C41.7375 6.08176 40.2332 8.17468 40.1678 10.333C40.1024 12.8183 40.2332 15.3037 40.2986 17.789C40.2986 18.5739 40.6256 19.2279 41.4759 19.4241C41.6721 19.4895 41.7375 19.8819 41.9337 20.2089C39.3829 20.2089 37.0938 20.2089 34.7393 20.2089C34.7393 20.0127 34.7393 19.7511 34.7393 19.4895C34.8701 19.4241 35.0009 19.3587 35.1971 19.3587C35.7203 19.2279 36.1128 18.9009 36.1782 18.3776C36.309 17.2658 36.4398 16.1539 36.4398 15.0421C36.4398 12.3605 36.4398 9.67896 36.3744 6.99741C36.3744 6.47418 36.2436 5.95095 36.1128 5.49313C35.9819 4.9699 35.7203 4.64289 35.1971 4.57748C34.6739 4.51208 34.6085 4.18506 34.7393 3.66183C35.1971 3.66183 35.6549 3.66183 36.1782 3.66183C37.0938 3.66183 38.0095 3.66183 38.9905 3.66183C39.3829 3.66183 39.5791 3.72723 39.5791 4.18506C39.5791 4.57748 39.71 4.9699 39.8408 5.49313C40.8218 4.31586 41.9991 3.59643 43.438 3.33481C45.0731 3.00779 46.7081 3.0732 48.2778 3.79264C50.1091 4.64288 51.0248 6.08176 51.0902 8.10927C51.1556 10.7908 51.1556 13.5378 51.221 16.2193C51.221 16.7425 51.2864 17.3312 51.3518 17.8544C51.4826 18.7701 51.875 19.4241 52.9215 19.5549C52.9215 19.8165 52.9215 20.0127 52.9215 20.2743C50.5016 20.2089 48.2124 20.2089 45.8579 20.2089Z"
      fill="black"
    />
    <path
      d="M89.8745 20.2743C87.3238 20.2743 85.0347 20.2743 82.6801 20.2743C82.6801 20.0127 82.6801 19.8165 82.6801 19.5549C84.3152 19.4241 84.3152 18.116 84.3152 17.0042C84.3806 13.6686 84.3152 10.333 84.3152 6.99741C84.3152 6.60499 84.2498 6.21257 84.1844 5.88555C84.0536 5.0353 83.5304 4.57748 82.6801 4.44667C82.6801 4.18505 82.6801 3.98885 82.6801 3.66183C84.1844 3.66183 85.6887 3.66183 87.193 3.66183C87.3238 3.66183 87.4546 3.85804 87.52 3.98885C87.6508 4.44667 87.7162 4.90449 87.7816 5.42772C88.6319 4.38127 89.6783 3.66183 90.921 3.40021C92.8177 2.94239 94.649 2.94239 96.4149 3.85804C98.05 4.70828 98.9656 6.08176 99.031 7.97847C99.0964 10.5292 99.0964 13.0799 99.1618 15.6307C99.1618 16.3501 99.2272 17.0042 99.2926 17.7236C99.3581 18.7047 99.7505 19.4241 100.862 19.4895C100.862 19.7511 100.862 19.9473 100.862 20.2089C98.5078 20.2089 96.2187 20.2089 93.8641 20.2089C93.8641 20.0127 93.8641 19.7511 93.8641 19.4895C95.303 19.3587 95.3684 18.2468 95.4338 17.135C95.4992 14.715 95.4992 12.2297 95.5646 9.80977C95.5646 9.35194 95.4992 8.95952 95.4338 8.5017C95.1068 6.99741 94.2566 6.34338 92.6869 6.27797C89.9399 6.08176 88.2394 8.04387 88.2394 10.2676C88.174 12.7529 88.3048 15.3037 88.3048 17.789C88.3048 18.6393 88.6319 19.2279 89.5475 19.4895C89.5475 19.4895 89.6783 19.8819 89.8745 20.2743Z"
      fill="black"
    />
    <path
      d="M116.559 3.46564C116.69 3.46564 116.821 3.46564 116.952 3.46564C117.148 3.46564 117.344 3.46564 117.409 3.59645C117.475 3.66185 117.54 3.79266 117.54 3.92347C117.54 4.11968 117.409 4.25048 117.279 4.31589C117.409 4.38129 117.475 4.44669 117.475 4.5775C117.54 4.77371 117.54 4.96992 117.606 5.03532H117.279C117.279 4.96992 117.213 4.83912 117.148 4.6429C117.082 4.44669 117.017 4.38129 116.886 4.38129H116.821V5.03532H116.494V3.46564H116.559ZM116.886 4.11967H117.017C117.148 4.11967 117.279 4.05427 117.279 3.85806C117.279 3.72725 117.213 3.66185 117.017 3.66185C116.952 3.66185 116.886 3.66185 116.886 3.66185V4.11967Z"
      fill="black"
    />
    <path
      d="M117.017 3.1386C117.606 3.1386 118.129 3.59643 118.129 4.25047C118.129 4.8391 117.671 5.36233 117.017 5.36233C116.363 5.36233 115.905 4.9045 115.905 4.25047C115.905 3.59643 116.428 3.1386 117.017 3.1386ZM117.017 2.81158C116.232 2.81158 115.578 3.46562 115.578 4.25047C115.578 5.03531 116.232 5.68934 117.017 5.68934C117.802 5.68934 118.456 5.03531 118.456 4.25047C118.456 3.46562 117.802 2.81158 117.017 2.81158Z"
      fill="black"
    />
  </svg>
);
